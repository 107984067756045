import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import Cookies from "js-cookie";

const mainNavigation = document.querySelector(".main-navigation");
const searchButton = document.querySelector(".main-navigation__search-button");
const searchInput = document.getElementById("field_menu_search");
const searchSubmit = document.getElementById("field_menu_submit");
const searchCloseButton = document.querySelector(
  ".main-navigation__search-close-icon"
);
const languageSwitcherButton = document.querySelector(
  ".language-switcher__button"
);
const languageSwitcherPopup = document.querySelector(
  ".language-switcher__popup"
);
const hasLanguageSwitcher = !!languageSwitcherButton;
const mainNavigationClose = document.querySelector(".main-navigation__close");
const languageSwitcherFade = document.querySelector(".language-switcher__fade");
const mainNavigationBurger = document.querySelector(".main-navigation__burger");
const mainNavigationTitles = document.querySelectorAll(
  ".main-navigation__menu-title"
);
const mainNavigationExpandRiskWarning = document.querySelector(
  ".main-navigation__rw--expand"
);

const openMobileMenu = () => {
  document.body.classList.add("overflow-scroll");
  mainNavigation.classList.add("main-navigation--open");
};

const closeMobileMenu = () => {
  document.body.classList.remove("overflow-scroll");
  mainNavigation.classList.remove(
    "main-navigation--open",
    "main-navigation--second"
  );
  mainNavigationTitles.forEach(function(section) {
    section.parentNode.classList.remove("open");
  });
};

const openLanguageSwitcher = () => {
  if (!hasLanguageSwitcher) {
    return;
  }

  document.body.classList.add("overflow-scroll");
  languageSwitcherPopup.style.top = `${mainNavigation.getBoundingClientRect()
    .height + 8}px`;
  languageSwitcherPopup.classList.add("open");
  mainNavigation.classList.add("language-switcher--open");
};

const closeLanguageSwitcher = () => {
  if (!hasLanguageSwitcher) {
    return;
  }

  document.body.classList.remove("overflow-scroll");
  languageSwitcherPopup.style.top = "";
  languageSwitcherPopup.classList.remove("open");
  mainNavigation.classList.remove("language-switcher--open");
};

if (hasLanguageSwitcher) {
  languageSwitcherButton.addEventListener("click", () => {
    openLanguageSwitcher();
  });
  languageSwitcherFade.addEventListener("click", () => {
    closeLanguageSwitcher();
  });
}

if (mainNavigationBurger) {
  mainNavigationBurger.addEventListener("click", () => {
    openMobileMenu();
  });
}
mainNavigationTitles.forEach(function(section) {
  section.addEventListener("click", function(e) {
    if (window.innerWidth >= 1024) {
      return;
    }

    if (!section.parentNode.classList.contains("open")) {
      mainNavigation.classList.add("main-navigation--second");
      section.parentNode.classList.add("open");
      return;
    }

    mainNavigation.classList.remove("main-navigation--second");
    section.parentNode.classList.remove("open");
  });
});

let searchWidgetInstalled = false;
let hashStore = "";

const installSearchWidget = () => {
  const apiKey = searchButton.dataset.apiKey;

  (function(w: any, d: any, t: any, u: any, n: any, s?: any, e?: any) {
    w["SwiftypeObject"] = n;
    w[n] =
      w[n] ||
      function() {
        (w[n].q = w[n].q || []).push(arguments);
      };
    s = d.createElement(t);
    e = d.getElementsByTagName(t)[0];
    s.async = 1;
    s.src = u;
    e.parentNode.insertBefore(s, e);
  })(
    window,
    document,
    "script",
    "//s.swiftypecdn.com/install/v2/st.js",
    "_st_menu_search"
  );
  // @ts-ignore
  _st_menu_search("install", apiKey, "2.0.0");

  searchWidgetInstalled = true;

  searchInput.addEventListener(
    "input",
    debounce(() => {
      if (searchInput.value && searchInput.value.length > 2) {
        searchSubmit.click();
      }
    }, 500)
  );
};

const openSearch = () => {
  hashStore = document.location.hash;
  searchInput.value = "";
  mainNavigation.classList.add("main-navigation--search-open");
  searchInput.focus();
};

const closeSearch = () => {
  mainNavigation.classList.remove("main-navigation--search-open");
  document.location.hash = hashStore;
  const searchContainerEl = document.querySelector(
    ".main-navigation__search--container div"
  ) as HTMLDivElement;

  if (searchContainerEl) {
    searchContainerEl.style.display = "none";
  }
};

if (searchButton && searchCloseButton) {
  searchButton.addEventListener("click", () => {
    if (!searchWidgetInstalled) {
      installSearchWidget();
    }

    openSearch();
  });

  searchCloseButton.addEventListener("click", () => {
    closeSearch();
  });
}

if (mainNavigationClose) {
  mainNavigationClose.addEventListener("click", () => {
    closeLanguageSwitcher();
    closeMobileMenu();
    closeSearch();
  });
}

if (mainNavigationExpandRiskWarning) {
  const RISK_WARNING_COOKIE = "riskWarningClosed";
  const regulatorName = document.body.getAttribute("data-regulator") || "fca";
  const closedRiskWarning = Cookies.get(RISK_WARNING_COOKIE) === regulatorName;
  let scrollPos = 0;
  let riskWarningOpened = true;

  const expandRiskWarning = () => {
    if (riskWarningOpened) {
      return;
    }

    scrollPos = 0;
    riskWarningOpened = true;
    mainNavigation.classList.add("main-navigation--rw-expand");
    Cookies.set(RISK_WARNING_COOKIE, "");
  };

  const closeRiskWarning = () => {
    if (!riskWarningOpened) {
      return;
    }

    scrollPos = 0;
    riskWarningOpened = false;
    mainNavigation.classList.remove("main-navigation--rw-expand");
    Cookies.set(RISK_WARNING_COOKIE, regulatorName);
  };

  if (closedRiskWarning) {
    closeRiskWarning();
  }

  mainNavigationExpandRiskWarning.addEventListener("click", e => {
    riskWarningOpened ? closeRiskWarning() : expandRiskWarning();
  });

  const checkScrollHandler = throttle(function() {
    if (window.innerWidth > 1023) {
      return;
    }

    if (scrollPos && this.scrollY > scrollPos) {
      closeRiskWarning();
    }

    scrollPos = this.scrollY;
  }, 100);

  window.addEventListener("scroll", checkScrollHandler);
}

function removeOpenClassFromMore() {
  const allMenuItems = document.querySelectorAll("[data-menu-item-more]");
  const allMenuIds = document.querySelectorAll("[data-menu-id-more]");

  allMenuItems.forEach(menuItem => {
    menuItem.classList.remove("open");
  });

  allMenuIds.forEach(menuId => {
    menuId.classList.remove("open");
  });
}

const menuItems = document.querySelectorAll("[data-menu-id-more]");
if (menuItems.length > 0) {
  const firstItem = menuItems[0];
  const target = document.querySelector(
    `[data-menu-item-more="${firstItem.getAttribute("data-menu-id-more")}"]`
  );
  if (target) {
    target.classList.add("open");
  }
  firstItem.classList.add("open");
}

menuItems.forEach(menuItem => {
  menuItem.addEventListener("mouseenter", event => {
    const targetId = (event.target as HTMLElement).getAttribute(
      "data-menu-id-more"
    );

    removeOpenClassFromMore();

    const target = document.querySelector(
      `[data-menu-item-more="${targetId}"]`
    );
    if (target) {
      target.classList.add("open");
    }

    (event.target as HTMLElement).classList.add("open");
  });
});
